<template>
  <div ref="poTemplate" class="px-md-6 py-6 py-md-12 overflow-scroll">
    <table id="header">
      <tr>
        <th id="header-col-1"></th>
        <th id="header-col-2"></th>
      </tr>
      <tr>
        <td id="logo-wrapper">
          <img
            :src="organizationLogo"
            alt="organization Logo"
            id="logo"
            v-if="organizationLogo"
          />
          <div class="my-logo" v-else>Your Logo</div>
        </td>
        <td id="document-type-wrapper">
          <p id="document-type">{{ documentTitle }}</p>
          <br />
          <b>{{ company_from }}</b
          ><br />
          {{ address_from }}
          <br />
          {{ zip_from }}
          <br />
          {{ country_from }}<br />
        </td>
      </tr>
    </table>
    <br />
    <hr style="border-color: #f2f2f2" />
    <table id="receiver-details-wrapper">
      <tr>
        <th id="receiver-details-wrapper-col-1"></th>
        <th id="receiver-details-wrapper-col-2"></th>
      </tr>
      <tr>
        <td>
          <b>For:</b><br />
          <b>{{ company_to }}</b>
          <br />
          {{ address_to }}<br />
          {{ zip_to }}<br />
          {{ city_to }} <br />
          {{ country_to }}<br />
          <br />
          <b style="padding-right: 4px">{{ counterpartyEmail }}</b
          ><br />
        </td>
        <td id="details-value-wrapper">
          <b>PO #: {{ ponumber }}</b
          ><br />
          <b style="padding-right: 4px">Date: </b> {{ date }}<br />
        </td>
      </tr>
    </table>
    <br />

    <table id="reference-table">
      <thead>
        <tr>
          <th id="reference-table-col-1"></th>
          <th id="reference-table-col-2"></th>
          <th id="reference-table-col-3"></th>
        </tr>

        <tr>
          <td>
            <b style="padding-left: 10px">Suppliers reference:</b> <br /><span
              style="padding-left: 10px"
              >{{ vendorRef }}</span
            >
          </td>
          <td>
            <b style="padding-left: 10px">Kevog's Reference:</b><br /><span
              style="padding-left: 10px"
              >{{ reference }}</span
            >
          </td>
          <td>
            <b style="padding-left: 10px">Incoterms</b><br /><span
              style="padding-left: 10px"
              >{{ deliveryRef }}</span
            >
            <br /><span style="padding-left: 10px"
              >Delivery lead time: {{ deliveryLeadTime }}</span
            >
          </td>
        </tr>
        <tr>
          <td>
            <b style="padding-left: 10px; font-size: 12px; margin-top: 6px"
              >Bill to:</b
            >
            <br /><b style="padding-left: 10px">{{ billRecipient }}</b
            ><br /><span style="padding-left: 10px"
              >TEL: {{ recipientTel }}</span
            >
          </td>

          <td colspan="2">
            <b style="padding-left: 10px; font-size: 12px; margin-top: 6px"
              >Ship to:
            </b>
            <br />
            <span style="padding-left: 10px">{{ shipto }}</span>
          </td>
        </tr>
      </thead>
    </table>
    <table id="products-table">
      <thead>
        <tr>
          <th id="products-table-col-1"></th>
          <th id="products-table-col-2"></th>
          <th id="products-table-col-3"></th>
          <th id="products-table-col-4"></th>
          <th id="products-table-col-5"></th>
          <th id="products-table-col-6"></th>
          <th id="products-table-col-7"></th>
        </tr>

        <tr>
          <td style="padding-left: 5px"><b>Items</b></td>
          <td align="left"><b>Description</b></td>
          <td style="padding-left: 5px"><b>QTY</b></td>
          <td style="padding-left: 5px"><b>UOM</b></td>
          <td style="padding-left: 10px">
            <b
              >Unit Price (<span>{{ currencySym || "" }}</span
              >)</b
            >
          </td>
          <td style="padding-left: 5px; padding-right: 5px">
            <b
              >Total Price (<span>{{ currencySym || "" }}</span
              >)</b
            >
          </td>
        </tr>
        <tr>
          <td colspan="7">
            <hr />
          </td>
        </tr>
      </thead>
      <tbody v-for="(line, index) in lines" :key="index">
        <tr>
          <td style="padding-left: 8px; vertical-align: top; padding-top: 8px">
            {{ index + 1 }}
          </td>

          <td
            style="padding-left: 8px; vertical-align: top; padding-top: 8px"
            align="center"
          >
            {{ line.description }}
          </td>
          <td
            style="padding-left: 8px; vertical-align: top; padding-top: 8px"
            align="right"
          >
            {{ line.quantity }}
          </td>
          <td style="padding-left: 8px; vertical-align: top; padding-top: 8px">
            {{ line.UOM }}
          </td>
          <td
            style="
              padding-left: 8px;
              vertical-align: top;
              padding-top: 8px;
              padding-right: 8px;
            "
            align="right"
          >
            {{ line.price }}
          </td>
          <td
            style="
              padding-left: 8px;
              vertical-align: top;
              padding-top: 8px;
              padding-right: 8px;
            "
            align="right"
          >
            {{ line.total }}
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td colspan="7">
            <hr />
          </td>
        </tr>
      </tfoot>
    </table>
    <br />
    <table id="total-wrapper">
      <tr>
        <th id="total-wrapper-col-1"></th>
        <th id="total-wrapper-col-2"></th>
      </tr>
      <tr>
        <td>
          <div class="totalCard">
            <small>Total amount</small><br />
            <b>{{ total | currency({ symbol: currencySym }) }}</b>
          </div>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>
          <p class="text-alert mt-2 mb-1">
            All prices {{ showTax ? "includes" : "excludes" }} VAT
          </p>

          <p class="text-alert text-capitalize mb-1">
            Delivery terms: {{ deliveryterms }}
          </p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import currencyFilter from "@/mixins/currencyFilter";

export default {
  name: "poTemplate",
  props: {
    documentTitle: {
      type: String,
      default: "Purchase Order",
    },
    company_from: {
      type: String,
      default: "",
    },
    address_from: {
      type: String,
      default: "",
    },
    zip_from: {
      type: [String, Number],
      default: " ",
    },
    city_from: {
      type: String,
      default: "",
    },
    country_from: {
      type: String,
      default: " ",
    },
    sender_custom1: {
      type: String,
      default: "",
    },
    sender_custom2: {
      type: String,
      default: "",
    },
    sender_custom3: {
      type: String,
      default: "",
    },
    company_to: {
      type: String,
      default: " ",
    },
    address_to: {
      type: String,
      default: " ",
    },
    zip_to: {
      type: [String, Number],
      default: " ",
    },
    city_to: {
      type: String,
      default: "",
    },
    country_to: {
      type: String,
      default: "",
    },
    client_custom1: {
      type: String,
      default: "",
    },
    client_custom2: {
      type: String,
      default: "",
    },
    client_custom3: {
      type: String,
      default: "",
    },
    ponumber: {
      type: String,
      default: "",
    },
    date: {
      type: [String, Date],
      default: "",
    },
    dueDate: {
      type: String,
      default: "",
    },
    lines: {
      type: [Array, Object],
    },

    tax: {
      type: [String, Number],
      default: "0.00",
    },
    total: {
      type: String,
      default: "",
    },
    bankName: {
      type: String,
    },
    bankNumber: {
      type: String,
    },
    bankAccountName: {
      type: String,
    },
    bankSortCode: {
      type: String,
    },
    showTax: {
      type: Boolean,
      default: true,
    },
    addon: {
      type: Boolean,
      default: false,
    },
    organizationLogo: String,
    currencySym: String,

    deliveryterms: String,
    shipto: String,
    reference: String,
    counterpartyEmail: String,
    vendorRef: String,
    ourRef: String,
    deliveryLeadTime: String,
    deliveryRef: String,
    billRecipient: String,
    recipientTel: String,
  },
  mixins: [currencyFilter],
};
</script>

<style lang="scss" scoped>
body {
  font-family: "Inter Tight", sans-serif;
}

.container {
  padding: 40px 200px;
}

table {
  width: 100%;
}

tr {
  line-height: 110%;
}

td {
  font-size: 10px;
  color: #525e6e;
}

b {
  color: #19283d;
}

#header-col-1 {
  width: 39%;
}

#header-col-2 {
  width: 61%;
}

#logo-wrapper {
  vertical-align: top;
  text-align: left;
}

#logo {
  max-width: 121px;
  max-height: 100px;
}

#document-type-wrapper {
  text-align: right;
  line-height: 1.2;
  vertical-align: top;
}

#document-type {
  font-size: 20px;
  font-weight: bold;
  color: #19283d;
  text-transform: capitalize;
}

.total {
  font-size: 16px;
  font-weight: bold;
}

#reference-table {
  vertical-align: top;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 18px;
}

#reference-table thead {
  vertical-align: text-top;
}

#reference-table thead tr:nth-child(2) {
  background-color: #f6f7f9;
  /* padding: 40px; */
  height: auto;
  vertical-align: auto;
}

#reference-table thead tr td {
  border: 1px solid #d7d7d7;
}

#reference-table thead tr td:first-child,
#reference-table thead tr td:nth-child(2),
#reference-table thead tr td:nth-child(3) {
  text-align: start;
}

#receiver-details-wrapper td {
  vertical-align: top;
}

#receiver-details-wrapper-col-1 {
  width: 50%;
}

#receiver-details-wrapper-col-2 {
  width: 35%;
}

#receiver-details-wrapper-col-3 {
  width: 15%;
}

#products-table {
  border-collapse: collapse;
  width: 100%;
  overflow-x: scroll;
}

#products-table thead {
  background-color: #f6f7f9;
}

#products-table thead tr td:nth-child(2) {
  text-align: center;
  padding: 3px 0;
}

#products-table thead tr td:nth-child(3) {
  text-align: right;
  padding: 3px 0;
  padding-right: 20px;
}

#products-table tbody tr td:nth-child(3),
#products-table tbody tr td:nth-child(4) {
  /* text-align: left; */
  padding: 3px 0;
  padding-right: 20px;
}

#products-table thead tr td:nth-child(5),
#products-table thead tr td:nth-child(6) {
  text-align: right;
  padding: 3px 0;
  padding-right: 8px;
}

#total-wrapper-col-1 {
  width: 50%;
}

#total-wrapper-col-2 {
  width: 30%;
}

#total-wrapper-col-3 {
  width: 20%;
}

#total-wrapper td {
  text-align: right;
}

.totalCard {
  width: 60%;
  height: auto;
  background-color: rgba(246, 247, 249, 1);
  border-radius: 4px;
  text-align: left;
  color: #19283d;
  font-family: "Inter Tight", sans-serif;
  padding: 16px;
}

.totalCard small {
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: rgba(127, 145, 155, 1);
}

.totalCard b {
  font-size: 1rem;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

@media screen and (max-width: 768px) {
  .totalCard {
    width: 100%;
  }
}

.text-alert {
  color: rgba(255, 106, 106, 1);
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  text-align: left;
}
</style>
